<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>스크립트조회</h1>
      <div class="is-right">
      </div>
    </div>
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="55px">
          <col width="100px">
          <col width="90px">
          <col width="100px">
          <col width="80px">
          <col width="300px">
          <col>
        </colgroup>
        <tr>
          <th><label>분류구분</label></th>
          <td>
            <v-select class="jh-form" v-model="CNSL_DIV" :items="dropcnslDivItems" item-text="title"></v-select>
          </td>
          <th><label>변경요청여부</label></th>
          <td>
            <v-select class="jh-form" v-model="SEARCH_REQ_NEWTY" :items="SEARCH_REQ_TY" item-text="CD_NM" item-value="CD"></v-select>                    
          </td>
          <th><label>스크립트명</label></th>
          <td>
            <v-text-field class="jh-form" name="searchCont"  @keypress.enter="searchScriptList(searchCont)" v-model="searchCont"></v-text-field>                    
          </td>
          <td class="has-search"><v-btn class="jh-btn is-search" @click="searchScriptList">조회</v-btn></td>
        </tr>
      </table>
    </div>
    <div class="jh-cols">      
      <!--왼쪽 프레임-->
      <div class="jh-form-wrap is-col-fix" style="width: 400px;">
        <div class="jh-tree" style="height: 670px;">
          <div class="jh-tree-header">
            <div class="is-left">
              <label>스크립트 분류</label>
            </div>
            <div class="is-right">
              <v-btn class="jh-btn is-sm" @click="unfoldTreeview">전체 펼치기</v-btn>
              <v-btn class="jh-btn is-sm" @click="foldTreeview">전체 접기</v-btn>
            </div>
          </div>
          <div class="jh-tree-body">
            <v-treeview ref="treeview1" :items="treeItems" item-key="id" tem-disabled="locked" activatable open-on-click color="secondary" class="jh-tree-view">
              <template v-slot:prepend="{ open, item }">
                <v-icon v-if="item.children">
                  {{open ? 'jh-icon-tree-folder-open' : 'jh-icon-tree-folder'}}
                </v-icon>
                <v-icon v-else>
                  {{ 'jh-icon-tree-file' }}
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                <span :class="[item.locked ? 'is-txt-blueGray is-mr-5' : '']" @click="getItemDetail(item)">{{ item.name }}</span>
                <v-icon>
                  {{ item.locked ? 'jh-icon-close-xs' : ''}}
                </v-icon>
              </template>
            </v-treeview>
          </div>
        </div>
      </div>
      <!--//왼쪽 프레임-->

      <!--오른쪽 프레임-->
      <div>
        <div class="jh-form-wrap">
          <div class="jh-ui-header">
            <h2>스크립트 리스트</h2>
          </div>
          <v-data-table
            dense
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="ROW_NUM"
            :page.sync="page"
            hide-default-footer
            class="jh-grid"
            height="380px"
            single-select
            @page-count="pageCount = $event"
            @click:row="getScriptDetail"
            fixed-header
            :item-class="isActiveRowScp"
          >
          </v-data-table>
            <div class="jh-pager">
              <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible"></v-pagination>
              <span class="jh-counter is-left">총 <em>{{ gridDataText.length }}</em> 건</span>
            </div>         
        </div>

        <div class="jh-form-wrap is-mt-15">
          <div class="jh-ui-header">
            <h2>스크립트 상세</h2>
          </div>
          <table class="jh-tbl-detail">
            <colgroup>
              <col width="120px">
              <col>
              <col width="120px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th><label>상위 스크립트명</label></th>
                <td><v-text-field class="jh-form is-md is-trns" v-model="upperScriptNm" name="upperScriptNm" disabled></v-text-field></td>
                <th rowspan="4"><label>스크립트 내용</label></th>
                <td rowspan="4" class="is-p-0"><v-textarea class="jh-form is-trns" style="height: 139px;" v-model="scriptDesc" :disabled="!isActive"></v-textarea></td>
              </tr>
              <tr>
                <th><label>스크립트명</label></th>
                <td><v-text-field class="jh-form is-md is-trns" v-model="thisScriptNm" name="thisScriptNm" disabled></v-text-field></td>
              </tr>
              <tr>
                <th><label>사용여부</label></th>
                <td><v-select class="jh-form is-md is-trns" v-model="selectedDrop" :items="useYnDrop" item-text="text" item-value="value" disabled></v-select></td>
              </tr>
              <tr>
                <th><label>정렬순서</label></th>
                <td><v-text-field class="jh-form is-md is-trns" type="number" v-model="ordSeq" name="ordSeq" value="0" disabled></v-text-field></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--//오른쪽 프레임-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

export default {
  mixins: [mixin],
  name: "MENU_E020609", //name은 'MENU_' + 파일명 조합

  components: {
    dataTables
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      aspCustKey: "001",
      selectedDrop: [],
      CNSL_DIV: "",
      USE_SRCH_YN: "",
      useYnDrop: [
        {text:"Y", value:"Y"},{text:"N", value:"N"}
      ],
      dropcnslDivItems: [
        {title: "전체", value: ""},
        {title: "대분류", value: "1"},
        {title: "중분류", value: "2"},
        {title: "소분류", value: "3"},
      ],
    pageCount: 0,
    page: 1,
    totalVisible: 10,

      alertMsg: {
        comChk: "회사구분란에서 회사를 선택 후 이용해주십시오.",
      },

      alertIcon: {
        noti: "jh-alert-icon is-info",
        err: "jh-alert-icon is-error",
      },

      alertType: {
        default: "default",
        confirm: "confirm",
      },

      treeItems: [],
      // dates: [
      //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //     .toISOString()
      //     .substr(0, 10),
      //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //     .toISOString()
      //     .substr(0, 10),
      // ],
      dates: [
        "2022-01-01",
        "2022-09-30",
      ],
      detailDates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      gridDataHeaders: [
        { text: "No", align: "center", value: "ROW_NUM", width: "40px", sortable: false, },
        { text: "대분류명", value: "SCRIPT_NM_1", width: "200px", },
        { text: "중분류명", value: "SCRIPT_NM_2", width: "200px", },
        // { text: "소분류명", value: "SCRIPT_NM_3", width: "200px", },
        { text: "스크립트명", value: "SCRIPT_TIT", },
        // { text: "분류", value: "LEVEL_NO_NM", align: "center", },
        // { text: "사용구분", value: "USE_TY", align: "center", sortable: true},
        { text: "사용여부", value: "USE_YN", align: "center", width: "80px", },
        { text: "변경요청", value: "REQ_YN", align: "center", width: "80px", },
      ],
      gridDataText: [],
      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '380px',
        itemKey: 'ROW_NUM',
        itemsPerPage: 500,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        disablePagination: true,
        itemClass: "isActiveRowScp"
      },
      gridFileHeaders: [
        { text : "파일명", value: "ORIGINAL_FILENAME", align:"left", sortable:true,},
        { text : "파일사이즈", value: "FILE_SIZE", align:"center", width: "100px", sortable:true,},
        { text : "다운로드", value: "DOWNLOAD", align:"center", width: "100px", sortable:true,},
        { text : "다운로드수", value: "DNLOD_CNT", align:"center", width:"100px", sortable:true,},
      ],
      gridFileText: [],
      gridFileSelected : {},
      scriptDesc: "",
      upperScriptNm: "",
      FILE_GROUP_KEY: "",
      ordSeq: 0,
      detailDateRange: "",
      clickedData: [],
      newScriptAdd: false,
      thisScriptNm: "",
      userYNScriptNm: "",
      ordSeqScriptNm: "",

      SEARCH_USE_TY:[],
      SEARCH_USE_NEWTY:"CHAT",

      SEARCH_REQ_TY:[
        {CD_NM:"전체", CD: ""},
        {CD_NM:"Y", CD: "reqY"},
        {CD_NM:"N", CD: "reqN"}
      ],
      SEARCH_REQ_NEWTY:"",

      USE_TY:[],
      USE_NEWTY:'',

      SCP_ID:'',

      searchCont:'',
    };
  },
  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    startDetailDate(e) {
      this.detailDates[0] = e;
    },
   /* endDetailDate(e) {
      this.detailDates[1] = e;
    },*/
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    endDetailDate(e) {
      this.detailDates[1] = e;
    },
    unfoldTreeview(){
      this.$refs.treeview1.updateAll(true);
    },
    foldTreeview(){
      this.$refs.treeview1.updateAll(false);
    },

    // 스크립트 목록에서 클릭 시 상담 스크립트 상세 스크립트 정보 출력
    async getScriptDetail(item, row){
      this.SCP_ID = item.SCRIPT_ID;

      let detailUrl = "/api/phone/script/inqire/detail";
      let fileUrl = "";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = detailUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire";
      requestData.headers["METHOD"] = "detail";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey;
      requestData.sendData["SCRIPT_ID"] = item.SCRIPT_ID;

      let response = await this.common_postCall(requestData);
      await this.setScriptDetail(response);
      this.gridFileText = await this.mixin_getFileList(this.FILE_GROUP_KEY);
    },

    isActiveRowScp(item){
      const activeClass = item.SCRIPT_ID === this.SCP_ID ? 'is-active' : '';
      return activeClass;
    },

    async setScriptDetail(response){
      let data = response.DATA;
      console.log("data", data);

      if( data.length == 1 ){
        // 신규를 누르거나 했을때 부모로 가져오기 위해서 데이터를 가지고 있음
        this.clickedData = data;

        this.upperScriptNm = data[0].UPPER_SCRIPT_NM; // 상위스크립트명
        this.thisScriptNm = data[0].SCRIPT_TIT;       // 스크립트명
        this.userYNScriptNm = data[0].USE_YN;         // 사용 여부
        this.ordSeqScriptNm = data[0].ORD_SEQ;        // 정렬 순서

        // 시작 ~ 종료 날짜
        this.detailDates[0] = data[0].BEGIN_DATE.substring(0, 4) + "-" + data[0].BEGIN_DATE.substring(4, 6) + "-" + data[0].BEGIN_DATE.substring(6, 8);
        this.detailDates[1] = data[0].EOT_DATE.substring(0, 4) + "-" + data[0].EOT_DATE.substring(4, 6) + "-" + data[0].EOT_DATE.substring(6, 8);
        this.detailDateRange = this.detailDates[0] + " ~ " + this.detailDates[1];

        this.USE_NEWTY = data[0].USE_TY;                 // 사용구분
        this.ordSeq = data[0].ORD_SEQ;                // 정렬순서
        data[0].SCRIPT_RMK = data[0].SCRIPT_RMK.replaceAll("\\r", "");
        data[0].SCRIPT_RMK = data[0].SCRIPT_RMK.replaceAll("&lt;", "<");
        data[0].SCRIPT_RMK = data[0].SCRIPT_RMK.replaceAll("&gt;", ">"); 
        this.scriptDesc = data[0].SCRIPT_RMK;         // 스크립트내용
        this.FILE_GROUP_KEY = data[0].FILE_GROUP_KEY;
        this.setSelected(data[0].USE_YN);
        //console.log(this.useYnDrop[0]);        // 사용여부
      }
    },

    async searchScriptList(){
      // 회사구분이 비었는지? 비었다면 알람창 띄워주기 회사구분은 필수 입력
      if (this.aspCustKey == null || this.aspCustKey == "") {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg: this.alertMsg.comChk,
          iconClass: this.alertIcon.err,
          type: this.alertType.default,
        });
        return false;
      }
      
      if (this.SEARCH_USE_NEWTY == "" || this.SEARCH_USE_NEWTY == undefined) {
        this.showAlert("사용구분을 선택해주세요.");
        return;
      }


      // 스크립트 목록 조회
      let scriptListUrl = "/api/phone/script/inqire/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = scriptListUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];           //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];               //접수종료일자
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;                     //업무구분
      requestData.sendData["SEARCH_CONT"] = this.searchCont;                   //처리구분
      // requestData.sendData["USE_TY"] = this.SEARCH_USE_NEWTY;                   //사용구분
      requestData.sendData["REQ_TY"] = this.SEARCH_REQ_NEWTY;                   //변경요청현황

      let response = await this.common_postCall(requestData);
      this.setScriptList(response);
      this.getTreeviewList();
      this.clickedData = [];
      this.upperScriptNm = "";
    //   let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
    //   this.detailDateRange = today + " ~ " + today;
      this.thisScriptNm = "";
      this.ordSeq = 1;
      this.userYNScriptNm = "";
      this.ordSeqScriptNm = "";
      this.scriptDesc = "";
      this.clickedData = [];
      this.newScriptAdd = false;
      //console.log(response);
    },
    // 스크립트 목록 set해주기
    setScriptList(response){
      let header = response.HEADER;
      let data = response.DATA;
        console.log("data  ", data);

      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){
        this.gridDataText = data;
        for(var i = 0; i < data.length; i++){
          if (this.gridDataText[i].CNT == '0') {
            this.gridDataText[i]['REQ_YN'] = 'N' 
          } else {
            this.gridDataText[i]['REQ_YN'] = 'Y'
          }
          let element = {
            id: data[i].C_NODE_NO,
            pid: data[i].P_NODE_NO,
            name: data[i].SCRIPT_TIT,
            nodeLvl: data[i].NODE_LVL,
            children:[],
          };
        }
        //console.log(data);
      } else {
        this.gridDataText = [];
      }
    },
    async getTreeviewList(){
      // 스크립트 트리뷰 조회
      let scriptListUrl = "/api/phone/script/inqire/tr/inqire";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = scriptListUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.tr.inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];        //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];          //접수종료일자
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;           //업무구분
      requestData.sendData["SEARCH_CONT"] = this.searchCont;     //처리구분
      // requestData.sendData["USE_TY"] = this.SEARCH_USE_NEWTY;    //사용구분
      requestData.sendData["REQ_TY"] = this.SEARCH_REQ_NEWTY;    //변경요청현황

      let response = await this.common_postCall(requestData);
      await this.setTreeviewList(response);
      this.unfoldTreeview();
    },
    async setTreeviewList(response){ // 트리뷰를 항목에 맞게 set해준다.
      let header = response.HEADER;
      let data = response.DATA;
      /*
        {
          id: 15,
          name: 'menu3 :',
          children: [
            { id: 16, name: 'menu3-1' },
            { id: 17, name: 'menu3-2' },
            { id: 18, name: 'menu3-3' },
          ],
        },
      */
      let items = [];
      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){

        if(this.CNSL_DIV == ""){ // 분류구분을 전체로 했을때 모든 트리뷰가 나오게

          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };

            if( element.nodeLvl == 2 ){
              for(var j = 0; j < items.length; j++){
                if( element.pid == items[j].id ){
                  //console.log(`push complate ${items[j].id}`);
                  items[j].children.push(element);
                }
              }
            } else if( element.nodeLvl == 3 ){
              for(var k = 0; k < items.length; k++){
                for(var l = 0; l < items[k].children.length; l++){
                  if( element.pid == items[k].children[l].id ){
                    //console.log(`push complate ${items[k].children[l].id}`);
                    items[k].children[l].children.push(element);
                  }
                }
              }
            } else {
              items.push(element);
            }
          }
        } else if(this.CNSL_DIV == 1){ // 분류 구분이 대분류라면 트리뷰에서 대분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        } else if(this.CNSL_DIV == 2){ // 분류 구분이 중분류라면 트리뷰에서 중분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        } else if(this.CNSL_DIV == 3){ // 분류 구분이 소분류라면 트리뷰에서 소분류만 나오게
          for(var i = 0; i < data.length; i++){
            let element = {
              id: data[i].C_NODE_NO,
              pid: data[i].P_NODE_NO,
              name: data[i].SCRIPT_TIT,
              nodeLvl: data[i].NODE_LVL,
              children:[],
            };
            items.push(element);

          }
        }

        this.treeItems = items;
        //console.log(this.treeItems);
      } else {
        this.treeItems = [];
      }

    },
    // 트리뷰에서 각 폴더, 파일모양을 클릭했을 때
    async getItemDetail(item){
      // 스크립트 트리뷰 조회
      let detailUrl = "/api/phone/script/inqire/detail";
      let fileUrl = "";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = detailUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.detail";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["SCRIPT_ID"] = item.id;     //처리구분

      let response = await this.common_postCall(requestData);
      //console.log(response);
      await this.setItemDetail(response);

      this.gridFileText = await this.mixin_getFileList(this.FILE_GROUP_KEY);

    },
    async setItemDetail(response){
      let data = response.DATA;
      /*
        ASP_CUST_KEY: "001"
        ASP_NEWCUST_KEY: "001"
        BEGIN_DATE: "20200917"
        EOT_DATE: "29991231"
        FILE_GROUP_KEY: ""
        LVL_NO: "1"
        ORD_SEQ: "1"
        SCRIPT_ID: "0002"
        SCRIPT_RMK: ""
        SCRIPT_TIT: "업무지원 스크립트"
        UPPER_SCRIPT_ID: ""
        UPPER_SCRIPT_NM: ""
        USE_YN: "Y"
      */
     /*
        scriptDesc: "",
        upperScriptNm: "",
        thisScriptNm: "",
        ordSeq: 0,
     */
      //console.log(data);
      if( data.length == 1 ){
        // 신규를 누르거나 했을때 부모로 가져오기 위해서 데이터를 가지고 있음
        this.clickedData = data;

        this.upperScriptNm = data[0].UPPER_SCRIPT_NM; // 상위스크립트명
        this.thisScriptNm = data[0].SCRIPT_TIT;       // 스크립트명
        this.userYNScriptNm = data[0].USE_YN;         // 사용 여부
        this.ordSeqScriptNm = data[0].ORD_SEQ;        // 정렬 순서

        // 시작 ~ 종료 날짜
        this.detailDates[0] = data[0].BEGIN_DATE.substring(0, 4) + "-" + data[0].BEGIN_DATE.substring(4, 6) + "-" + data[0].BEGIN_DATE.substring(6, 8);
        this.detailDates[1] = data[0].EOT_DATE.substring(0, 4) + "-" + data[0].EOT_DATE.substring(4, 6) + "-" + data[0].EOT_DATE.substring(6, 8);
        this.detailDateRange = this.detailDates[0] + " ~ " + this.detailDates[1];

        this.USE_NEWTY = data[0].USE_TY;                 // 사용구분
        this.ordSeq = data[0].ORD_SEQ;                // 정렬순서
        this.scriptDesc = data[0].SCRIPT_RMK;         // 스크립트내용
        this.FILE_GROUP_KEY = data[0].FILE_GROUP_KEY; // 파일그룹키
        this.setSelected(data[0].USE_YN);
        //console.log(this.useYnDrop[0]);        // 사용여부

      }
    },
    setSelected(val){
      this.selectedDrop = { text: val, value: val };
    },

    // 트리뷰 클릭시 list에는 하위 리스트들이 나오게
    async gettreeViewClickList(data){

      // 트리뷰 클릭했을때 해당 분류 하위부분이 목록에 list가 되게끔
      let scriptListUrl = "/api/phone/script/inqire/low/list";
      let requestData = {
        headers: {},
        sendData:{}
      };

      requestData.headers["URL"] = scriptListUrl;
      requestData.headers["SERVICE"] = "phone.script.inqire.low";
      requestData.headers["METHOD"] = "list";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = true;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspCustKey; //회사코드
      requestData.sendData["START_DATE"] = this.dates[0];        //접수시작일자
      requestData.sendData["END_DATE"] = this.dates[1];          //접수종료일자
      requestData.sendData["CNSL_DIV"] = this.CNSL_DIV;           // 분류 구분 ->  트리뷰에서 대 / 중 / 소에 따라서 바뀌어야 한다.
      requestData.sendData["USE_SRCH_YN"] = this.USE_SRCH_YN;     //처리구분
      requestData.sendData["SCRIPT_ID"] = data[0].SCRIPT_ID;     // 현재 분류 id
      requestData.sendData["LVL_NO"] = data[0].LVL_NO;     //현재 분류 구분 번호


      let response = await this.common_postCall(requestData);
      this.settreeViewClickList(response);
    },

    settreeViewClickList(response){
      let header = response.HEADER;
      let data = response.DATA;

      if( header.ERROR_FLAG != true && parseInt(header.COUNT) > 0 ){
        this.gridDataText = data;
        for(var i = 0; i < data.length; i++){
          let element = {
            id: data[i].C_NODE_NO,
            pid: data[i].P_NODE_NO,
            name: data[i].SCRIPT_TIT,
            nodeLvl: data[i].NODE_LVL,
            children:[],
          };
        }
        //console.log(data);
      } else {
        this.gridDataText = [];
      }
    },


    changeSelected(e){
      //console.log(e);
      this.selectedDrop = {text: e.text, value: e.value};
    },

    clearScreen(){
      this.searchScriptList();
      this.getTreeviewList();
      this.upperScriptNm = "";
      let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      this.detailDateRange = today + " ~ " + today;
      this.thisScriptNm = "";
      this.ordSeq = 1;
      this.scriptDesc = "";
      this.clickedData = [];
      this.newScriptAdd = false;
      this.FILE_GROUP_KEY = "";
      this.gridFileText = [];
    },

    showAlert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },

    
    setGirdFileSelected(item){
      this.gridFileSelected = item;
    },
    isActiveRow(item){
      return item.FILE_KEY == this.gridFileSelected.FILE_KEY? 'is-active':'';
    },
  },
  computed: {
    isActive(){
      let rtn = false;
      if(this.inputState != ""){
        rtn = true;
      }else{
        rtn = false;
      }
      return rtn
    },    
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    detailDateRangeText() {
      var newStartDate = this.detailDates[0];
      var newEndDate = this.detailDates[1];
      this.detailDateRange = newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  async mounted() {
    // this.searchScriptList();
    // this.getTreeviewList();
    let codeName = ["SCP001"];
    let common_code = await this.mixin_common_code_get_all(codeName);
    this.SEARCH_USE_TY = [...this.SEARCH_USE_TY, ...common_code[0].group_value];
    this.USE_TY = common_code[0].group_value;
  },
  created() { 
    this.dataTableOptions.headers = this.gridDataHeaders;
    this.dataTableOptions.items = this.gridDataText;
  }
};
</script>

<style>

</style>
